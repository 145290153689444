// Trustpilot API key and company ID
// TODO: Change for production
export const apiKey = 'U7MtYnMOGRBJnFK3KARAn9PIJ1sSFjh9';
// export const apiKey = 'ff351b2fc51365e3145bd40763863bd8';
export const companyId = '5fc9fbc913159100018c2511';
// Development API key (cloudpillo-new)
// export const themeApiKey = '3b4749336da1f99247775f4bd7e105e4'
// Production API key (cloudpillo-nl)
export const themeApiKey = '219beb277d04010c8fef369ccbff60f3';
export const apiKeyKlaviyoPublic = 'TiV6GP';
export const klaviyoListId = 'R7RWTC';
export const klaviyoAdditionalListId = 'XUJNue';
